import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Barlow\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-sans\",\"weight\":[\"100\",\"200\",\"300\",\"400\",\"500\",\"600\",\"700\",\"800\",\"900\"],\"preload\":false}],\"variableName\":\"fontSans\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"variable\":\"--font-din\",\"src\":[{\"path\":\"../lib/fonts/DINNextW1G-Bold.otf\",\"weight\":\"700\"},{\"path\":\"../lib/fonts/DINNextW1G-Regular.otf\",\"weight\":\"400\"},{\"path\":\"../lib/fonts/DINNextW1G-Medium.otf\",\"weight\":\"500\"},{\"path\":\"../lib/fonts/DINNextW1G-Italic.otf\",\"weight\":\"600\"}],\"preload\":false}],\"variableName\":\"dinNext\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/i18n/LocaleSwitcherSelect.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/app/src/components/ui/sonner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/app/src/components/ui/Toaster.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/lib/styles/globals.css");
