'use client';

import { useTheme } from 'next-themes';
import { useEffect, useState } from 'react';
import { Toaster as Sonner } from 'sonner';

function useToasterPosition() {
    const [position, setPosition] = useState<'top-center' | 'bottom-right'>('bottom-right');

    useEffect(() => {
        const updatePosition = () => {
            if (window.innerWidth < 768) {
                setPosition('top-center');
            } else {
                setPosition('bottom-right');
            }
        };

        updatePosition();
        window.addEventListener('resize', updatePosition);

        return () => window.removeEventListener('resize', updatePosition);
    }, []);

    return position;
}

type ToasterProps = React.ComponentProps<typeof Sonner>;

const Toaster = ({ ...props }: ToasterProps) => {
    const { theme = 'system' } = useTheme();
    const toasterPosition = useToasterPosition();
    return (
        <Sonner
            theme={theme as ToasterProps['theme']}
            // eslint-disable-next-line tailwindcss/no-custom-classname
            className="toaster group"
            position={toasterPosition}
            toastOptions={{
                classNames: {
                    toast: 'group toast group-[.toaster]:bg-background group-[.toaster]:text-foreground group-[.toaster]:border-border group-[.toaster]:shadow-lg',
                    description: 'group-[.toast]:text-muted-foreground',
                    actionButton: 'group-[.toast]:bg-primary group-[.toast]:text-primary-foreground',
                    cancelButton: 'group-[.toast]:bg-muted group-[.toast]:text-muted-foreground',
                },
            }}
            {...props}
        />
    );
};

export { Toaster };
